import React, { useState, useRef } from "react";

const Form = ({ setInputText, todos, setTodos, inputText, setStatus }) => {
  const [displayFilter, setDisplayFilter] = useState(false);
  const inputRef = useRef("");

  const inputTextHandler = (e) => {
    setInputText(e.target.value);
  };

  const filterHandler = () => {
    if (displayFilter === true) {
      setDisplayFilter(false);
    } else if (displayFilter === false) {
      setDisplayFilter(true);
    }
  };

  const submitTodoHandler = (e) => {
    e.preventDefault();
    if (inputText === "" || inputText === null) {
      inputRef.current.focus();
      return;
    }
    setTodos([
      ...todos,
      {
        text: inputText,
        completed: false,
        id: Math.random() * 1000,
      },
    ]);
    setInputText("");
  };

  const statusHandler = (status) => {
    setStatus(status.target.value);
    setDisplayFilter(false);
  };

  return (
    <div className="container-form">
      <form className="form">
        <p className="input-header">Enter the task to be added</p>
        <div className="select">
          <label className="select-filter">
            <i className={"fas fa-filter"} onClick={filterHandler}></i>
          </label>
          <select
            onChange={statusHandler}
            name="todos"
            className={`filter-todo  ${displayFilter ? "active" : ""}`}
          >
            <option value="all">All</option>
            <option value="completed">Completed</option>
            <option value="uncompleted">Not completed</option>
          </select>
        </div>

        <input
          ref={inputRef}
          value={inputText}
          onChange={inputTextHandler}
          placeholder="Enter task here"
          type="text"
          className="todo-input"
        />
        <button
          onClick={submitTodoHandler}
          className="btn todo-button"
          type="submit"
        >
          <i className="fas fa-plus-square"></i>
        </button>
      </form>
    </div>
  );
};

export default Form;
